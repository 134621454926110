import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// Styles
import "./skodel-services.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import StackedImage from "../components/StackedImage";
import FAQ from "../components/FAQ";
import BookDiscussion from "../components/BookDiscussion";

// Images and icons
import ServiceHero from "../assets/images/strategy-hero.jpg";
import ServiceCardBG from "../assets/images/leading-card-bg.png";
import IconWindowWhite from "../assets/icons/icon-window-white.svg";
import IconWindowDark from "../assets/icons/icon-window-dark.svg";
import IconBarWhite from "../assets/icons/icon-bar-white.svg";
import IconBarDark from "../assets/icons/icon-bar-dark.svg";
import IconMessageWhite from "../assets/icons/icon-message-white.svg";
import IconMessageDark from "../assets/icons/icon-message-dark.svg";
import IconGroupWhite from "../assets/icons/icon-group-white.svg";
import IconGroupDark from "../assets/icons/icon-group-dark.svg";
import IconBookWhite from "../assets/icons/icon-book-white.svg";
import IconArchiveWhite from "../assets/icons/icon-archive-white.svg";

import StrategyCollaborate from "../assets/images/strategy-collaborate.jpg";
import MartynCampbell from "../assets/images/martyn-campbell.png";
import ExperiencedTeam from "../assets/images/workplaces-experienced-team.png";
import Documentation from "../assets/images/workplaces-documentation.png";
import Reports from "../assets/images/workplaces-reports.png";
import Support from "../assets/images/workplaces-support.png";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const ServicesValueData = [
  {
    id: "SV0001",
    imageWhite: IconWindowWhite,
    imageDark: IconWindowDark,
    title: "Save resources",
    description:
      "There’s no trade-off with resources in order to meet compliance requirements so you can meet requirements without disrupting business priorities.",
  },
  {
    id: "SV0002",
    imageWhite: IconBarWhite,
    imageDark: IconBarDark,
    title: "Proven approach",
    description:
      "An approach designed alongside regulators specialising in psychosocial safety so you can be sure you’re adopting a validated approach. No more uncertainty.",
  },
  {
    id: "SV0003",
    imageWhite: IconMessageWhite,
    imageDark: IconMessageDark,
    title: "Reduce risk",
    description:
      "Skodel gives leaders a simple compliance process to make taking action easy so you can avoid the risks associated with inaction. No more delayed action.",
  },
  {
    id: "SV0004",
    imageWhite: IconGroupWhite,
    imageDark: IconGroupDark,
    title: "Zero disruption",
    description:
      "Our approach is fast and simple with zero disruption. Everything is designed and ready to go for you.",
  },
];

const ObligationsData = [
  {
    id: "Obligation0001",
    icon: IconBookWhite,
    title: "Introduction to psychosocial risk assessments",
    link: "https://www.loom.com/share/bd6963e174784117bd31d337843d9939",
  },
  {
    id: "Obligation0002",
    icon: IconArchiveWhite,
    title: "List of controls by hazard",
    link: "https://blog.skodel.com/a-list-of-psychosocial-hazard-controls-by-hazard/",
  },
];
const FAQData = [
  {
    id: "FAQ0001",
    question: "Can Skodel be customised?",
    answer:
      "Yes, parts of Skodel can be tailored to your organisation’s needs. We can discuss your customisation requirements.",
  },
  {
    id: "FAQ0002",
    question: "Can Skodel be integrated?",
    answer:
      "Yes, Skodel can be integrated and we can discuss your integration requirements.",
  },
];

const META_TAGS = {
  title: "Meet your workplace’s psychosocial requirements",
  description:
    "Confidently meet your workplace’s psychosocial safety requirements without resource tradeoffs and disruption to other business priorities.",
};

const Workplaces = () => {
  const [servicesValue, setServicesValue] = useState<
    {
      id: string;
      imageWhite: string;
      imageDark: string;
      title: string;
      description: string;
    }[]
  >([]);
  const [faq, setFAQ] = useState<
    { id: string; question: string; answer: string }[]
  >([]);

  useEffect(() => {
    setServicesValue(ServicesValueData);
    setFAQ(FAQData);
  }, [setServicesValue, setFAQ]);

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        {/*  Primary Meta Tags */}
        <title>{META_TAGS.title}</title>
        <meta name="title" content={META_TAGS.title} />
        <meta name="description" content={META_TAGS.description} />

        {/*  Open Graph / Facebook  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skodel.com/workplaces" />
        <meta property="og:title" content={META_TAGS.title} />
        <meta property="og:description" content={META_TAGS.description} />
        <meta property="og:image" content={ServiceHero} />

        {/*  Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://skodel.com/workplaces" />
        <meta property="twitter:title" content={META_TAGS.title} />
        <meta property="twitter:description" content={META_TAGS.description} />
        <meta property="twitter:image" content={ServiceHero} />
      </Helmet>
      <Page>
        <section className="section-service-hero">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${ServiceHero}')` }}
          ></div>
          <div className="main-container">
            <div className="service-hero-content">
              <h1>Meet your workplace’s psychosocial requirements</h1>
              <p>
                Confidently meet your workplace’s psychosocial safety
                requirements without resource tradeoffs and disruption to other
                business priorities.
              </p>
              <div className="service-hero-button">
                <NavLink
                  to={"/request-a-demo"}
                  className="btn btn-white btn-hero-cta"
                >
                  Book A Demo
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        <section className="section-service-middle">
          <div className="main-container">
            <div className="service-content-wrapper">
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>Know what to do and how to do it</h2>
                  <p>
                    Know what is required and how you can do this, simply.
                    Skodel takes the complexity and uncertainty out of
                    psychosocial compliance for you.
                  </p>
                </div>
                <div className="home-report-content">
                  <div className="home-report-video">
                    <div className="video-wrapper">
                      <iframe
                        src="https://www.loom.com/embed/1aeb83ef743e4f738ac3fd6a5d8651ce?sid=dc56d107-8f45-4a19-854d-cc1a813b680b"
                        frameBorder="0"
                        width="100%"
                        height="600"
                        allowFullScreen
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>The simplest way to meet compliance</h2>
                  <p>
                    Skodel gives you a seamless and automated psychosocial risk
                    management process aligned with relevant legislation so you
                    can meet compliance with ease.
                  </p>
                </div>
                <div className="service-value-grid column-4">
                  {servicesValue?.map((item, index) => (
                    <div className="service-card" key={item?.id + "-" + index}>
                      <img src={ServiceCardBG} alt={"Skodel"} />
                      <div className="service-icon">
                        <img src={item?.imageWhite} alt={"Skodel"} />
                        <img src={item?.imageDark} alt={"Skodel"} />
                      </div>
                      <div className="service-description">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="stacked-content">
                <StackedImage
                  image={MartynCampbell}
                  description={
                    "“In 2024 every CEO and Executive needs to understand their duties and get psychosocial risks on their radar. Beyond compliance, creating safe workplaces drives performance and enables good culture. Skodel’s easy to use features and robust controls ensure companies meet their compliance obligations without exposing leaders and their teams to greater risk from missed or mismanaged psychosocial hazards.”"
                  }
                  author={
                    "Martyn Campbell, Former CEO SafeWork SA and board member Safework Australia"
                  }
                />
              </div>
              <div className="service-zigzag-content">
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={ExperiencedTeam} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>It’s all designed and ready for you</h3>
                    <p>
                      Training, resources, risk assessments and survey design
                      with hazard scoring all in one place for you. We remove
                      the pain and complexity of designing a system to meet
                      psychosocial safety compliance that works with the
                      resources you have available.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={Documentation} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Maintain a compliant risk assessment with ease</h3>
                    <p>
                      Filling out your psychosocial risk assessment with
                      controls and scoring hazards based on worker consultation
                      data isn’t easy. We give you a ready made template and
                      then score and update it for you with the simplest worker
                      consultation process possible.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper strategy-collaborate">
                  <div className="service-zigzag-image">
                    <img src={StrategyCollaborate} alt={"Skodel"} />
                    <div className="strategy-collaborate-chart">
                      <img src={Reports} alt={"Skodel"} />
                    </div>
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Easily integrated into your risk system</h3>
                    <p>
                      We’ve designed a system for psychosocial safety that is
                      comprehensive and end-to-end yet exceptionally simple. It
                      manages all the time consuming and complex aspects of
                      psychosocial risk and can easily be integrated into your
                      overall risk management system.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={Support} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Always up to date training</h3>
                    <p>
                      Access the latest training modules for leaders and
                      employees, updated annually by top psychologists and
                      safety specialists. Ensure your team stays informed and
                      compliant with ease. Simplify training, enhance safety.
                    </p>
                  </div>
                </div>
              </div>
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>Psychosocial risk assessments</h2>
                </div>
                <div className="action-grid">
                  {ObligationsData?.map((item, index) => (
                    <a
                      className="action-card"
                      key={item?.id + "-" + index}
                      href={item.link}
                      target="_blank"
                    >
                      <img src={ServiceCardBG} alt={"Skodel"} />
                      <div className="service-icon">
                        <img src={item?.icon} alt={"Skodel"} />
                        <img src={item?.icon} alt={"Skodel"} />
                      </div>
                      <div className="service-description">
                        <h4>{item?.title}</h4>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              <div className="service-faq-wrapper">
                <div className="section-title text-center">
                  <h2>Frequently asked questions</h2>
                </div>
                <div className="service-faq-content">
                  <div className="service-faq-list">
                    {faq?.map((item, index) => (
                      <FAQ
                        key={item?.id + "-" + index}
                        question={item?.question}
                        answer={item?.answer}
                      />
                    ))}
                  </div>
                  <BookDiscussion
                    title={"Book in a discussion"}
                    link={"/request-a-demo"}
                    button={"Request A Demo"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default Workplaces;
